.App {
  margin: 0px;
  padding: 0px;
  /* background-color: rgb(211, 211, 211); */
}

.App-format {
  margin: 2vh;
}

.App-header {
  text-align: center;
  background-color: #000000;
  height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 10px;
  transition: 0.6s;
  border: 2px solid black;
}

.App-header .hover-text {
  transition: 0.6s;
  display: none;
  font-size: calc(8px + 2vmin);
}

.App-header:hover .hover-title {
  transition: 0.6s;
  display: none;
}
.App-header:hover .hover-text {
  transition: 0.6s;
  display: block;
}

.App-subheader {
  color: #000000;
}

.App-body {
}

.Photo-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Artist-photo {
  flex: 1;
  max-height: 25vh;
  max-width: 25vh;
  height: 10vh;
  box-shadow: black;
  border-radius: 10px;
  margin-top: 2vh;
  margin-left: 2px;
  margin-right: 2px;
}

.Artist-info {
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: 0.6s;
  border: 2px solid black;
  padding: 2vh;
  margin-top: 1vh;
}

.Artist-info:hover {
  background-color: rgb(230, 230, 230);
}

.Artist-info ::selection {
  color: rgb(255, 255, 255);
  background: black;
}

.Artist-info-format {
  margin-left: 1em;
  margin-top: 0px;
}

.Artist-name {
}

.Artist-photo:hover {
  transition: 0.3s;
  opacity: 50%;
}

.App-footer {
  text-align: center;
  margin: 2vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
